/*!
 * Slick Slider 1.8.1 (http://kenwheeler.github.io/slick/)
 * Copyright 2013-2016
 * Licensed under MIT (https://github.com/kenwheeler/slick/blob/master/LICENSE)
 */

$slick-font-path: "../../resources/vendor/slick/fonts/";
$slick-font-family: "slick";
$slick-loader-path: "../../resources/vendor/slick/";

@import "../vendor/slick/scss/slick.scss";
@import "../vendor/slick/scss/slick-theme.scss";
