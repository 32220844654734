.hero__arrow {
  display: none; }

.hero {
  height: 100vh;
  @include media-breakpoint-up(md) {
    min-height: 700px; }

  position: relative;
  .hero__col,
  .hero__row {
    min-height: calc(100% - #{$navbar-height}); } }

.hero__arrow {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  font-size: 40px;
  transform: translateX(-50%);
  color: $white;
  transition: all 1s ease; }


.dark-theme .hero__arrow {
  color: $black; }


.navbar--scrolled .hero__arrow {
  opacity: 0; }
