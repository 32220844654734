footer {
  color: $gray-400;
  background-color: $black;
  a {
    color: $white;
    &:hover {
      color: $gray-400; } }

  .border-top {
    border-color: rgba(255,255,255,0.2) !important; } }
