// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

//
// Color system
//

$white:       #fff;
$gray-100:    #f3f5f8;
$gray-200:    #e9ecef;
$gray-300:    #dee2e6;
$gray-400:    #ced4da;
$gray-500:    #adb5bd;
$gray-600:    #868e96;
$gray-700:    #495057;
$gray-800:    #343a40;
$gray-900:    #212529;
$black:       #15344a;



// $primary:     #00a7b3
// $secondary:   #f67113

$light-blue:  #909aa0;
$light-blue-02: #EDEEFB;

$light:       $gray-100;
$dark:		  $black;

$red:         #d63c5f;
$blue:		  #4d628d;
$green:		  #5c7a4c;

$navbar-height: 110px;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 2rem;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           40px !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base:                 sofia-pro, sans-serif;
$font-size-base:                   1rem !default; // Assumes the browser default, typically `16px`
// $font-size-lg:                  ($font-size-base * 1.25) !default
// $font-size-sm:                  ($font-size-base * .875) !default

// $font-weight-light:             300 !default
// $font-weight-normal:            400 !default
// $font-weight-bold:              500 !default

// $font-weight-base:              $font-weight-normal !default
// $line-height-base:              1.5 !default

// $h1-font-size:                  $font-size-base * 3.125
// $h2-font-size:                  $font-size-base * 1.875
// $h3-font-size:                  $font-size-base * 1.25
// $h4-font-size:                  $font-size-base * 1
// $h5-font-size:                  $font-size-base * 1.25 !default
// $h6-font-size:                  $font-size-base !default

// $h1-font-size:                  55px
// $h2-font-size:                  30px
// $h3-font-size:                  20px
// $h4-font-size:                  16px

// $headings-margin-bottom:        1rem
// $headings-font-family:          inherit !default
// $headings-font-weight:          $font-weight-normal
// $headings-line-height:          1.2 !default
// $headings-color:                inherit !default


// Body
//
// Settings for the `<body>` element.

$body-bg:                       $white !default;
$body-color:                    $black;


// Links
//
// Style anchor elements.

// $link-color:                    $primary
// $link-decoration:               none
// $link-hover-color:              $primary
$link-hover-decoration:         none;


// Paragraphs
//
// Style p element.

// $paragraph-margin-bottom:       1rem !default


// Navbar

// $navbar-nav-link-padding-x:         0

$navbar-light-color:                $black;
$navbar-light-hover-color:          $gray-800;
$navbar-light-disabled-color:       $gray-400;
$navbar-light-active-color:         $gray-600;
$navbar-light-toggler-border-color: $black;


// Navs

// $nav-link-padding-x:                0
// $nav-link-padding-y:                0.3rem

// hr

$hr-border-color:               rgba($black, .15) !default;


// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;


// Badges

$badge-pill-padding-x:              1rem;
