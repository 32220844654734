.blog__title-box {
  @include media-breakpoint-up(lg) {
    margin-top: -89px;
    background: $white; } }

.blog__quote {
  position: relative;
  padding-top: 2rem;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 1px;
    background: $black; } }
