.image {
  width: 100%;
  background-position: center center;
  padding-bottom: 56.25%; }

.image--wide {
  padding-bottom: 41.83%; }

.image--ultrawide {
  padding-bottom: 36.23%; }
