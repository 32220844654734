body:not(.dark-theme) .navbar-toggler {
  border-color: $white; }

body:not(.navbar--scrolled) .navbar .navbar-toggler-icon {}
body:not(.dark-theme) .navbar .navbar-toggler-icon {
  color: $white;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") {} }

body:not(.dark-theme) .navbar-toggler {
  border-color: $white; }

.navbar {
  background: none !important;
  transition: none;
  .js--transition & {
    transition: background 0.3s ease-out; }

  @include media-breakpoint-down(md) {
    .navbar-collapse {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

  &.navbar--has-shadow {
    background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%) !important; }

  // .js--top &
  //   position: absolute

  .navbar-brand {
    padding: 0; }

  .navbar-brand__logo {
    width: 90px;
    @include media-breakpoint-up(md) {
      width: 120px; }
    .cls-1 {
      transition: background 0.3s ease-out;
      fill: $white; } }

  .navbar-nav .nav-link {
    transition: all 0.5s ease;
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    outline: 0;
    &:hover {
      color: $gray-400;
      outline: 0; }

    &:active {
      color: $gray-400;
      background: transparent !important;
      outline: 0; } }

  .navbar__btn {
    border-color: $white;
    background: transparent;
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px; }

  .dark-theme & {
    .navbar-brand__logo {
      .cls-1 {
        fill: $black; } }

    .navbar-toggler {
      border-color: $black; }

    .navbar-collapse {
      background: none; }

    .navbar-toggler-icon {
      background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2315344a' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") {} }

    .navbar-nav .nav-link {
      color: $black;
      &:hover {
        color: $gray-600; } }

    .navbar__btn {
      border-color: $black;
      background: transparent;
      color: $black; }

    .navbar__btn:hover,
    .navbar__btn:not(:disabled):not(.disabled).active,
    .navbar__btn:not(:disabled):not(.disabled):active {
      background: $black;
      color: $white; } }

  .navbar__btn:hover {
    background: $white;
    color: $black; }

  .navbar__btn:not(:disabled):not(.disabled).active,
  .navbar__btn:not(:disabled):not(.disabled):active {
    background: $white;
    color: $black; } }



body {
  background: $black;
  transition: none;
  &.js--transition {
    transition: background 0.3s ease-out; }

  &.navbar--scrolled {
    background: $white; }

  &.navbar--scrolled,
  &.navbar--open {

    .navbar-collapse {
      background: none; }

    .navbar-brand__logo {
      .cls-1 {
        fill: $black; } }

    .navbar {
      background: $white !important;
      .navbar-nav .nav-link {
        color: $black;
        &:hover {
          color: $gray-600; } }

      .navbar__btn {
        border-color: $black;
        color: $black; }

      .navbar-toggler {
        border-color: $black; }

      .navbar-toggler-icon {
        background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2315344a' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") {} }

      .navbar__btn:hover {
        border-color: $black;
        background: $black;
        color: $white; }

      .navbar__btn:not(:disabled):not(.disabled).active,
      .navbar__btn:not(:disabled):not(.disabled):active {
        border-color: $black;
        background: $black;
        color: $white; } } } }

.nav-item {
  @include media-breakpoint-up(lg) {
    margin-left: 1rem; } }
