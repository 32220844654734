.image-section {
  position: relative; }

@include media-breakpoint-up(lg) {
  .image-section__content {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    color: $white; } }
