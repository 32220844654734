@import "custom-variables"; // <--------- Add variables to overwrite Bootstrap variables here
@import "assets/bootstrap"; // <--------- Loads Bootstrap v4.3.1
@import "assets/slick"; // <--------- Loads Slick Slider 1.8.1
@import "assets/fancybox"; // <--------- Loads fancybox 3.5.7

// components
@import "components/type";
@import "components/container";
@import "components/navbar";
@import "components/images";
@import "components/hero";
@import "components/pricing-boxes";
@import "components/slider";
@import "components/footer";
@import "components/image-section";
@import "components/blog";
@import "components/icons";
@import "components/utilities";

// base styles
.hero {
  padding-top: $navbar-height;
  position: relative; }

.vertical-divider {
  width: 1px;
  height: 300px;
  background-color: $white;
  @include media-breakpoint-down(md) {
    height: 200px; } }


.hero--bg-home {
  background: url("../images/frontend/bg-home-6.jpg") no-repeat center center;
  background-size: cover; }

.hero--bg-automobile {
  background: url("../images/frontend/bg-automobile.png") no-repeat center center;
  background-size: cover; }

.hero--bg-immobilien {
  background: url("../images/frontend/bg-immobilien.png") no-repeat center center;
  background-size: cover; }

.hero--bg-beteiligungen {
  background: url("../images/frontend/bg-beteiligungen.png") no-repeat center center;
  background-size: cover; }


.facts {
  color: $light-blue; }

.facts__col {
  @include media-breakpoint-down(lg) {
    margin-bottom: 80px; }
  @include media-breakpoint-down(md) {
    max-width: 100% !important;
    width: 100%;

    .type--display-3 {
      margin-right: 0 !important; }

    .type--display-1 {
      font-size: 90px;
      line-height: 110px;

      i {
        font-size: 92px;
        sup {
          font-size: 54px; } } } }

  @include media-breakpoint-down(md) {
    .type--display-1 {
      font-size: 65px;
      line-height: 80px;

      i {
        font-size: 66px;
        sup {
          font-size: 38px; } } } } }

.facts__line {
  margin: 0;
  padding: 0;
  background: $light-blue;
  width: calc(100% + 7vw);
  display: block;
  height: 1px; }

.facts__col--left .facts__line {
  margin-left: -7vw; }

.facts__col--right .facts__line {
  margin-right: -7vw; }


.facts__number {
  position: relative;
  margin-bottom: -20px;
  @include media-breakpoint-down(md) {
    margin-bottom: -10px; } }

.facts__col--1 {
  .facts__label {
    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: 42px;
      left: 100%;
      margin-left: 20px; } } }



.image-section {
  height: 100vh;
  background-image: url("../images/frontend/bg.png");
  background-size: cover;
  color: $white; }

.list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }



.navbar-toggler {
  border: none; }
