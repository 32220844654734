.slick-slide {
  height: auto; }

.slick-dots {
  bottom: -2.5rem; }

.slick-dots li {
  button {
    &:before {
      font-size: 10px; }

    &:hover, &:focus {
      &:before {
        color: $primary;
        opacity: 1; } }

    &:before {
      color: $gray-400;
      opacity: 1; } }

  &.slick-active button:before {
    color: $primary;
    opacity: 1; } }
