@import url("https://use.typekit.net/udl2zfl.css");

@font-face {
  font-family: 'Austin';
  src: url('../fonts/austin/Austin-MediumItalic.woff2') format('woff2'), url('../fonts/austin/Austin-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic; }

.type--display-1 {
  font-size: 130px;
  line-height: 150px;
  font-weight: 500;
  i {
    font-family: 'Austin', serif;
    font-size: 133px; }

  &.h-row--3 {
    margin-top: -40px; }

  @include media-breakpoint-down(sm) {
    font-size: 80px;
    line-height: 90px;
    font-weight: 500;

    &.h-row--3 {
      margin-top: -24px; }

    i {
      font-family: 'Austin', serif;
      font-size: 81px; } }

  // @include media-breakpoint-down(md)
  //   font-size: 55px
 }  //   line-height: 63px

.type--display-2 {
  font-size: 93px;
  line-height: 110px;
  font-weight: 500;
  i {
    font-family: 'Austin', serif; }

  @include media-breakpoint-down(md) {
    font-size: 55px;
    line-height: 63px; } }

.immobilien .type--display-2 {
  &.h-row--2 {
    margin-top: -33px; }

  @include media-breakpoint-down(md) {
    &.h-row--2 {
      margin-top: -16px; } } }

.automobile .type--display-2 {
  &.h-row--3 {
    margin-top: -33px; }

  @include media-breakpoint-down(md) {
    &.h-row--3 {
      margin-top: -16px; } } }

.type--display-3 {
  font-size: 60px;
  line-height: 68px;
  font-weight: 500;
  i {
    font-family: 'Austin', serif; }

  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 41px; } }

.type--heading {
  font-size: 38px;
  line-height: 48px;
  font-weight: 500;
  i {
    font-family: 'Austin', serif; }

  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 40px; } }

.type--subheading {
  font-size: 35px;
  line-height: 52px;
  font-weight: 300;
  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 32px; } }

.type--title {
  font-size: 28px;
  line-height: 44px;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    font-size: 22px;
    line-height: 28px; } }

.type--subtitle {
  font-size: 28px;
  line-height: 44px;
  font-weight: 300;
  @include media-breakpoint-down(md) {
    font-size: 22px;
    line-height: 28px; } }

.type--title {
  font-size: 28px;
  line-height: 44px;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    font-size: 22px;
    line-height: 28px; } }

.type--title-sm {
  font-size: 22px;
  line-height: 36px;
  font-weight: 600; }

.type--subtitle-sm {
  font-size: 22px;
  line-height: 36px;
  font-weight: 300; }

.type--label {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500; }

.type--highlight {
  font-size: 35px;
  line-height: 52px;
  font-weight: 300;
  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 38px; } }

.type--highlight-sm {
  font-size: 22px;
  line-height: 33px;
  font-weight: 300;
  font-style: italic; }

.type--base {
  font-size: 16px;
  line-height: 26px; }

.type--lg {
  font-size: 18px;
  line-height: 1.4; }

.type--xl {
  font-size: 22px;
  line-height: 36px;
  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 32px; } }

.type--sm {
  font-size: 14px;
  line-height: 17px; }

.type--xs {
  font-size: 12px;
  line-height: 16px; }

.type--display-number {
  font-size: 105px;
  line-height: 1;
  font-weight: 300; }


.type--text-links a {
  color: $red; }

.btn {
  font-size: 16px; }

.btn-lg {
  font-size: 18px; }
