.pricing-box-01,
.pricing-box-02 {
  position: relative;
  &:after {
    content: "";
    position: absolute; } }

.pricing-box-01:after {
  z-index: -1;
  height: 410px;
  width: 497px;
  left: -105px;
  top: -96px;
  background: url("../images/frontend/pricing-bg-01.png");
  background-size: cover; }

.pricing-box-02:after {
  z-index: -1;
  height: 440px;
  width: 430px;
  right: -105px;
  bottom: -78px;
  background: url("../images/frontend/pricing-bg-02.png");
  background-size: cover; }

.pricing-box__price {
  position: relative; }

.pricing-box__label {
  position: absolute;
  opacity: 0.8;
  top: 15px;
  font-weight: 300; }

.pricing-box__subtext {
  opacity: 0.7; }
